import blank from '../experiments/Blank.logo';
import ModelMolecules from '../experiments/ModelMolecules.logo';
import BasicBoard from '../experiments/BasicBoard.logo';
import Motion from '../experiments/Motion.logo';
import LightShow from '../experiments/LightShow.logo';
import Position from '../experiments/Position.logo';
import Packets from '../experiments/PacketsNew.logo';
import IntroToSensors from '../experiments/IntroSensors.logo';

export const experimentsList = [
    {
        name: "Blank",
        code: blank,
        view: 'main'
    },
    {
        name:"Light Show",
        code: LightShow,
        view: 'main'
    },
    {
        name: "BasicBoard",
        code: BasicBoard,
        view: 'main'
    },
    {
        name: "Intro to Sensors",
        code: IntroToSensors,
        view: 'data'
    },
    {
        name: "Force & Motion",
        code: Motion,
        view: 'motion'
    },
    {
        name: "Packets",
        code: Packets,
        view: 'data'
    },
    {
        name: "Modeling Molecules",
        code: ModelMolecules,
        view: 'main'
    },
    {
        name:"Position",
        code: Position,
        view: 'data'
    },
];