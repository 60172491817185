export const includes = 
`
    to receive-packet
        if (is-defined '_packet-length') = false
        [
            make '_packet-length' 6
        ]



        make '_checksum' 0

        make '_packet-type' 'T1'
        ;always T1 right now because these aren't defined yet

        let "i 0
        make '_last-packet' :_packet-type
        make "_last-packet se :_last-packet now ;replacing 'now' with 'now_milli' to get more accurate time
        make "_last-packet se :_last-packet :_packet-length

        repeat ( :_packet-length ) [
            let "_adc-holder readADC :i
            make "_last-packet se :_last-packet ( :_adc-holder )
            let 'i' :i + 1
            make "_checksum :_checksum + (:_adc-holder)
        ]

        make "_last-packet se :_last-packet :_checksum

        if (is-defined '_packet-log') = false 
        [
            make '_packet-log' false
        ]

       if (:_packet-log = true)[
            logData :_last-packet
        ]

    end

    to ReceivePacket 
        if (is-defined '_packet-length') = false
        [
            make '_packet-length' 6
        ]
        
        make '_checksum' 0

        let "i 0
        let "date_time now_milli
        let "date nth 0 :date_time
        let "time nth 1 :date_time
        let "unix_time now_milli_unix

        make '_last-packet' :date
        make "_last-packet se :_last-packet :time
        make "_last-packet se :_last-packet :unix_time

        repeat ( :_packet-length ) [
            let "done false
            
            ;recieve values as ADC units
            if (:i < 4) [ ;I'm not sure why I set this to 6, 4 would probably make more sense based on the cases below
                let "_adc-holder readADC :i
            ]

            ;Case check for lux and celcius conversion
            if (:i = 0) [
                let "_lux ConvertToLux :_adc-holder
                make "_last-packet se :_last-packet ( round :_lux )
                let "done true
            ]
            if (:i > 0 and :i < 4) [
                ;Celcius
                let "_celcius round (0.5348 * (:_adc-holder - 148.6)) + 13
                make "_last-packet se :_last-packet ( :_celcius )
                let "done true
            ]
            
            ;Removing the motion sensor data calls for the January PLE, will be put back after
            if (:i >= 4) [ ;this line basically skips the calls if the packet count is set too high, will remove when the other data is put back.
                let "done true
            ]
            ;if (:i = 4) [
            ;    ;cm
            ;    make "_last-packet se :_last-packet readPing
            ;    let "done true
            ;]
            ;if (:i = 5) [
            ;    ;cm/s
            ;    make "_last-packet se :_last-packet readPing 
            ;    let "done true
            ;]
            ;if (:i = 6) [
            ;    ;cm/s2
            ;    make "_last-packet se :_last-packet readPing 
            ;    let "done true
            ;]
                      
            if (:done = false)[ make "_last-packet se :_last-packet ( :_adc-holder ) ]
            let 'i' :i + 1
            make "_checksum :_checksum + (:_adc-holder)
        ]

        ;Check if the packet is being logged
        if (is-defined '_packet-log') = false 
        [
            make '_packet-log' false
        ]
        if (:_packet-log = true)[
            logData :_last-packet
        ]
    end


    to RecieveMotionPacket
        _packetSetup
        
        set-packet-count 6
        set-packet-save true

        make "_last-packet se :_last-packet readPing
        make "_last-packet se :_last-packet readPing
        make "_last-packet se :_last-packet readPing

        print :_last-packet

        _logCheck
    end


    to _packetSetup
        if (is-defined '_packet-length') = false
        [
            make '_packet-length' 6
        ]
        
        make '_checksum' 0

        let "i 0
        let "date_time now_milli
        let "date nth 0 :date_time
        let "time nth 1 :date_time
        let "unix_time now_milli_unix

        make '_last-packet' :date
        make "_last-packet se :_last-packet :time
        make "_last-packet se :_last-packet :unix_time
    end

    to _logCheck
        ;Check if the packet is being logged
        if (is-defined '_packet-log') = false 
        [
            make '_packet-log' false
        ]
        if (:_packet-log = true)[
            logData :_last-packet
        ]
    end



    to recievePacketInCelcius
        make '_packet-length' 6
        make '_checksum' 0

        make '_packet-type' 'T1'
        ;always T1 right now because these aren't defined yet

        let "i 0
        make '_last-packet' :_packet-type
        make "_last-packet se :_last-packet now
        make "_last-packet se :_last-packet :_packet-length

        repeat ( :_packet-length ) [
            let "_adc-holder readADC :i
            ADUtoCelcius :_adc-holder
            let "x readADUAsCelcius :i
            print 'ADU: '
            print :x
            let "_adc-holder :output
            make "_last-packet se :_last-packet ( :_adc-holder )
            let 'i' :i + 1
            make "_checksum :_checksum + (:_adc-holder)
        ]

        make "_last-packet se :_last-packet :_checksum

        if (is-defined '_packet-log') = false 
        [
            make '_packet-log' false
        ]

       if (:_packet-log = true)[
            logData :_last-packet
        ]
    
    end

    to GetTempADCs
        make "adcList []
        push "adcList readADC1
        push "adcList readADC2
        push "adcList readADC3
        print :adcList
    end

    to CalibrateTempSensor :adcVal :sensorNum
        if (:sensorNum = 1) [
            ifelse (is-defined "slope1) [	
                let "temp :slope1 * :adcVal + :b1
                return round :temp
            ][print 'Sensor 1 not calibrated']
        ]
        if (:sensorNum = 2) [
             ifelse (is-defined "slope2) [
                let "temp :slope2 * :adcVal + :b2
                return round :temp
            ][print 'Sensor 2 not calibrated']
        ]
        if (:sensorNum = 3) [
            ifelse (is-defined "slope3) [    
                let "temp :slope3 * :adcVal + :b3
                return round :temp
            ][print 'Sensor 3 not calibrated']
        ]
        
        return :adcVal
    end

    to CalibrationSetup :adcLow :tmpLow :adcHigh :tmpHigh :sensorNum  
        let "slope (:tmpHigh - :tmpLow) / (:adcHigh - :adcLow)
        let "b :tmpLow - (:slope * :adcLow)

        if (:sensorNum = 1) [
            make "slope1 :slope
            make "b1 :b
        ]
        if (:sensorNum = 2) [
            make "slope2 :slope
            make "b2 :b
        ]
        if (:sensorNum = 3) [
            make "slope3 :slope
            make "b3 :b
        ]
    end

    to ConvertToLux :adu
        let "_lux (4.2866 * :adu) - 2.6713
        if :_lux < 0 [make "_lux 0]
        return :_lux
    end

    to ADUtoCelcius :adu
        make "output ( :adu * 5 ) / 1024.9
        make "output ( :output - 0.5 ) * 100
    end

    to set-packet-count :n
        make '_packet-length' :n
    end

    to set-packet-save :value
        make '_packet-log' :value
    end

    to set-packet-mode :mode
        make '_packet-mode' :mode
    end

    to readLightSensor
        output readADC2
    end

    to readTempSensor0
        output readADC0
    end

    to readTempSensor1
        output readADC1
    end

    to readTempSensor2
        output readADC2
    end

    to showxy
        print tcor
    end




`;
