import React from 'react';
import { selectData } from '../redux/reducers/packetDataSlice'
import { useAppSelector } from '../redux/hooks';

const MotionTable = (props) => {
    const packetData = useAppSelector(selectData);

    let row = 0;

    return(
<div style={{ overflow: "scroll", height: "100%", width: "100%" }}>
            <span style={{ marginLeft: "20px", cursor: 'pointer' }}>Import</span>
            
            <table style={{ width: "80%" }}>
                <thead style={{ textAlign: "center" }}>
                    <tr>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Unix Time(ms)</th>
                        <th>cm</th>
                        <th>cm/s</th>
                        <th>cm/s²</th>
                    </tr>
                </thead>
                <tbody style={{ textAlign: 'center' }}>
                    {packetData.map(dataLine => {
                        row++;//added to stop getting warnings about matching keys when time is the same (less than a second)
                        return (
                            <tr key={`${dataLine[0]}row${row}`}>
                                <td key={`${dataLine[0]}${row}date`} >{dataLine[0]}</td>
                                <td key={`${dataLine[0]}${row}time`}>{dataLine[1]}</td>
                                <td key={`${dataLine[0]}${row}unix`}>{dataLine[2]}</td>
                                <td key={`${dataLine[0]}${row}ping`}>{dataLine[3]}</td>
                                <td key={`${dataLine[0]}${row}ms`}>{dataLine[4]}</td>
                                <td key={`${dataLine[0]}${row}ms2`}>{dataLine[5]}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default MotionTable;